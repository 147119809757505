<template>
	<div class="index" >

		<div class="index2">
			<!-- <p style="margin-bottom: 80px;">{{$t('top.DepositWithdrawTishi')}}</p> -->
			<div style="margin: 10px 0;" class="re_from">
				<div style="text-align: center;">提现金额</div>
				<div>
					<label class="re_laber_input">可提现金额 {{money}}</label>
					<div><input type="text" placeholder="Enter money" class="re_input" v-model="amount" /></div>
				</div>
				<div style="display: flex;justify-content: center;">
					<div class="btn_small_1" @click="withdraw()"><span>提现</span></div>
					<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="withdraw()"><span>提现</span></button> -->
				</div>

				<div style="display: flex;justify-content: center;">
					<div class="btn_small_2" @click="back()"><span>返回</span></div>
					<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="back()"><span>返回</span></button> -->
				</div>
				
				<div v-if="withdraw_List.length>0" style="text-align: center;">提现记录</div>
				<div class="table4" v-if="withdraw_List.length>0">
					<div style="display: flex;justify-content: space-between;margin: 5px;padding:5px; border-bottom: 2px solid #343B44;font-size: 12px;">
						<div>金额</div>
						<div>时间</div>
						<div>状态</div>
					</div>
					<div v-for="(item,index) in withdraw_List" style="display: flex;justify-content: space-between;margin: 5px;padding:5px; border-bottom: 2px solid #343B44;font-size: 12px;">
						<div style="color: #55CDEB;">{{item.amount}}</div>
						<div>{{item.create_time }}</div>
						<div v-if="item.status == 0" style="color: #9EA3A7;">等待审核</div>
						<div v-if="item.status == 1" style="color: #55CDEB;">通过审核</div>
					</div>
				</div>
				
			</div>
			
		
		
		</div>

	</div>
</template>

<script>
	import request from '@/utils/request'
	import {formatDate} from '@/utils/relUrl'
	export default {
		name: 'User_withdraw',
		data() {
			return {
				wallet_address:'',
				invite_code:'',//invite_code
				username:'',
				money:0,
				discount_amount:0,
				user_info:[],
				cz_address:'',
				amount:'',
				withdraw_List:[],

			};
		},
		activated() {
			//console.log('我这个页面显示就会执行');
			let _this = this;
			//刷新
			if (_this.$route.params.refresh) {
				_this.$router.go(0)
			}
			_this.local_data();
			
		},
		mounted() {
			let _this = this;
		},
		filters:{
			
			formatDate (time){
				let date = new Date(time);
				return formatDate(date,'yyyy-MM-dd hh:mm');
			}
		},
		
		methods: {
			//获取信息
			local_data(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				if (token == null || token == '' || token == 'null' || token == undefined || token == 'undefined') {
					this.hasLogin = 0;
					window.sessionStorage.clear();
					this.$router.replace({ name: 'Login', params: { refresh: true }})
				}else{
					this.hasLogin = 1;
					_this.account();
					_this.withdrawList();
				}
				
				
			},

			account(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				request.post('finance/user/index', {
					token: token,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.code ==0){
						window.sessionStorage.setItem('user_info',JSON.stringify(res.data.data));
						_this.user_info = JSON.parse(window.sessionStorage.getItem('user_info'));
						_this.invite_code = _this.user_info.invite_code;
						_this.wallet_address = _this.user_info.wallet_address;
						_this.username = _this.user_info.username;
						_this.money = _this.user_info.money;
						_this.discount_amount = _this.user_info.discount_amount;
						
					}else{
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
				});
			},
			//返回首页
			back(){
				this.$router.replace({ name: 'User', params: { refresh: true }})
			},
			
			//提现金额
			withdraw(){
				//https://api.blockmoneychain.com/finance/payment/withdraw
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				let amount = this.amount;
				request.post('finance/payment/withdraw', {
					token: token,
					amount:amount,
				}).then(res => {
					//console.log(res)
					this.amount = ''
					//结果
					if(res.data.code ==0){
						
						_this.$message({
						    message: '提现成功,等待审核通过',
						    type: 'success'
						});
						_this.withdrawList();
					}else{
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
					
				});
			},
			
			//提现记录https://api.blockmoneychain.com/finance/payment/withdrawList
			withdrawList(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				request.post('finance/payment/withdrawList', {
					token: token,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.code ==0){
						
						this.withdraw_List = res.data.data.list;
					}else{
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
				});
			},
			





		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.index{
		
		background:url(../assets/images/blockmoney/bg.png);
		background-size: cover;
		background-position: 50%;
		height: calc(100vh - 60px);
		display: flex;
		justify-content: center;
		/* align-items: center; */
	}
	.index2{
		border: 2px solid #6DB0C6;
		border-radius: 5px;
		background: linear-gradient(180deg, #0C4781, #051022 30%);
		padding: 20px 20px;
		width: 90%;
		max-width: 600px;
		/* height: 320px; */
		margin-top: 30px;
		margin-bottom: 30px;
		overflow: auto;
		
	}
	.btn_blue_class {
		-webkit-box-align: center;
		align-items: center;
		border: 0px;
		border-radius: 5px;
		box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
		cursor: pointer;
		display: inline-flex;
		font-family: inherit;
		font-size: 14px;
		font-weight: 600;
		-webkit-box-pack: center;
		justify-content: center;
		letter-spacing: 0.03em;
		line-height: 1;
		opacity: 1;
		outline: 0px;
		transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
		height: 36px;
		padding: 0px 16px;
		background-color: #015DB4;
		color: white;
		width: 100%;
		
	}

	.re_from {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
		margin-bottom: 10px
	}

	.re_laber_input {
		font-size: 14px;
		font-weight: 500;
		color: #fff;
		margin-bottom: 10px;
		display: block
	}

	.re_input {
		height: 36px;
		line-height: 36px;
		width: 100%;
		background-color: #021327;
		border: 1px solid #596775;
		border-radius: 10px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding: 0 10px;
		word-wrap:break-word;
		word-break:normal; 
	}
	.re_input2 {
		height: 36px;
		line-height: 36px;
		width: 100%;
		background-color: #021327;
		/* border-bottom: 1px solid #596775; */
		border-radius: 10px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding: 0 10px;
		word-wrap:break-word;
		word-break:normal; 

	}
	.table4{
		width: 100%;
		height: 200px;
		background-color: #051022;
		border: 2px solid #343B44;
		margin-top: 10px;
	}
	.btn_small_1{
		width: calc(100% - 5px);
		height: 50px;
		background:url(../assets/images/blockmoney/btn_small_1.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 600px;
	}
	.btn_small_2{
		width: calc(100% - 5px);
		height: 50px;
		background:url(../assets/images/blockmoney/btn_small_2.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 600px;
	}
</style>
